import React, { useEffect } from 'react';

const useOutside = (
  refs: Array<React.RefObject<HTMLElement>>,

  callback: () => void,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside = refs.every(
        (ref) =>
          ref.current && !ref.current.contains(event.target as Node | null),
      );
      if (isClickOutside) callback();
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback]);
};

export default useOutside;
