import { Product } from '@/types/products';
import getProductLowerPrice from '@/utils/products/getProductLowerPrice';

const getProductLowerUnitPrice = (product: Product): number => {
  const size = product.specs.variantInfo?.bundle;
  if (size) {
    const productTiersSorted = product.price.tiers.sort(
      (a, b) => a.unitPrice / size - b.unitPrice / size,
    );
    const productFirstTier = productTiersSorted[0];
    if (!productFirstTier) throw new Error('No tiers found');
    return Math.trunc((productFirstTier.unitPrice / size) * 100) / 100;
  }
  return getProductLowerPrice(product);
};

export default getProductLowerUnitPrice;
