/*
 * Image types and definitions
 */

import { CropInfo } from '@web-apps/cropper';

// DO NOT CHANGE THE ORDER OF THE ENUM
export enum UploaderStatus {
  Added = 'ADDED',
  Preparing = 'PREPARING',
  Prepared = 'PREPARED',
  Uploading = 'UPLOADING',
  Uploaded = 'UPLOADED',
  Failed = 'FAILED',
}

export interface UploaderImagePrepared {
  width: number;
  height: number;
  crop: CropInfo;
  newFile?: File;
}

export interface UploaderImageToProcess {
  nativeFile: File;
  skipQualityResize: boolean;
}

export type CustomImageContent<T> = T extends
  | UploaderStatus.Added
  | UploaderStatus.Preparing
  ? UploaderImageToProcess
  : T extends UploaderStatus.Prepared | UploaderStatus.Uploading
    ? UploaderImagePrepared & {
        blobUrl: string;
      }
    : T extends UploaderStatus.Uploaded
      ? UploaderImagePrepared & {
          blobUrl?: string;
          url: string;
          storageKey: string;
        }
      : T extends UploaderStatus.Failed
        ? {
            error: Error;
          }
        : never;

export type UploaderImage<T extends UploaderStatus> = {
  id: string;
  previousStatuses: UploaderStatus[];
  status: T;
  name: string;
  size: number;
} & CustomImageContent<T>;
