import React, { useId } from 'react';
import clsx from 'clsx';

import { ACCEPTED_IMAGE_FORMAT } from '@/constants';

import styles from './styles.module.scss';

interface Props {
  multiple?: boolean;
  callback: (files: File[]) => void;
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
}

function ImportButton({
  callback,
  children,
  multiple = true,
  disabled = false,
  className,
  onClick,
}: Props) {
  const inputId = useId();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const receivedFiles = (e.target && e.target.files) || undefined;
    if (!receivedFiles) throw new Error('No files uploaded');

    callback(Array.from(receivedFiles));
    e.target.value = '';
  };

  return (
    <div className={clsx(styles.containerInput, className)}>
      <label
        className={styles.importInput}
        htmlFor={inputId}
        aria-label="Import"
      >
        <input
          id={inputId}
          type="file"
          data-testid="file-input"
          onClick={onClick}
          onChange={onChange}
          multiple={multiple}
          data-role="none"
          accept={ACCEPTED_IMAGE_FORMAT.join(', ')}
          disabled={disabled}
        />
      </label>
      {children}
    </div>
  );
}

export default ImportButton;
