import { PopupChildrenProps } from './types';

const reducer = (
  state: PopupChildrenProps,
  newProps: Partial<PopupChildrenProps>,
): PopupChildrenProps => ({
  ...state,
  ...newProps,
});

export default reducer;
