'use client';

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import useOutside from 'hooks/useOutside';

import Button from '@/components/Button';
import Icon from '@/components/Icon';

import {
  defaultProps,
  FullscreenContext,
  FullscreenContextActions,
} from './index';
import reducer from './reducer';
import {
  FullScreenButtonPosition,
  FullScreenButtonStyle,
  FullScreenCartParams,
  FullScreenPosition,
  PopupChildrenProps,
} from './types';

import styles from './styles.module.scss';

export default function FullScreenProvider({ children }: PropsWithChildren) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [popupChildren, dispatch] = useReducer(reducer, defaultProps);

  const [fullScreenParams, setFullScreenParams] =
    useState<FullScreenCartParams>({});

  const setPopupChildrenCustom = useCallback(
    (props?: Partial<PopupChildrenProps>) => {
      if (!props) dispatch(defaultProps);
      else
        dispatch({
          ...defaultProps,
          ...props,
        });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setPopupChildrenCustom();
    popupChildren.onClose?.();
  }, [setPopupChildrenCustom, popupChildren]);

  useOutside([wrapperRef, ...(popupChildren.excludedCloseRefs || [])], () => {
    if (!popupChildren.closeBackgroundTapped) return;
    handleClose();
  });

  useEffect(() => {
    if (!!popupChildren.content || fullScreenParams.cartItemId) {
      document.body.style.overflow = 'hidden';
    } else if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'visible';
    }
  }, [popupChildren.content, fullScreenParams.cartItemId]);

  return (
    <FullscreenContext.Provider
      value={useMemo(
        () => ({
          popupChildren,
          fullScreenParams,
        }),
        [popupChildren, fullScreenParams],
      )}
    >
      <FullscreenContextActions.Provider
        value={useMemo(
          () => ({
            setPopupChildren: setPopupChildrenCustom,
            setFullScreenParams,
          }),
          [setPopupChildrenCustom, setFullScreenParams],
        )}
      >
        {!!popupChildren.content && (
          <div
            className={clsx(
              styles.container,
              styles[popupChildren.size],
              popupChildren?.position === FullScreenPosition.Bottom &&
                styles.bottom,
              popupChildren.transparentBackground && styles.transparent,
            )}
            data-cy="fullscreen-catalog"
          >
            <div
              className={clsx(
                styles.content,
                popupChildren.theme && styles[popupChildren.theme],
              )}
              ref={wrapperRef}
            >
              {popupChildren.hasClosingButton && (
                <div
                  className={clsx(
                    styles.closeContainer,
                    popupChildren.closingButtonPosition ===
                      FullScreenButtonPosition.Outside && styles.outside,
                    popupChildren.closingButtonPosition ===
                      FullScreenButtonPosition.Fullscreen && styles.fullscreen,
                  )}
                >
                  <Button callback={handleClose} theme="transparent">
                    <div
                      className={clsx(
                        styles.closeIcon,
                        styles[
                          popupChildren.closingButtonStyle ||
                            FullScreenButtonStyle.Secondary
                        ],
                        !!popupChildren.closingButtonRounded && styles.rounded,
                      )}
                    >
                      <Icon name="removeCross" />
                    </div>
                  </Button>
                </div>
              )}
              {popupChildren.content}
            </div>
          </div>
        )}
        {children}
      </FullscreenContextActions.Provider>
    </FullscreenContext.Provider>
  );
}
