import DoneView from '@/components/popups/DoneView';
import FailedView from '@/components/popups/FailedView';
import ProgressView from '@/components/popups/ProgressView';
import {
  FullScreenSize,
  SetPopupChildren,
} from '@/context/FullScreenContext/types';
import { UploaderStatus } from '@/context/Uploader/types/image';
import {
  UploaderJob,
  UploaderJobStatusChangedCallback,
} from '@/context/Uploader/types/job';

const togglePreparingLoaderAndDonePopup = ({
  setPopupChildren,
}: {
  setPopupChildren: SetPopupChildren;
}): UploaderJobStatusChangedCallback<UploaderStatus> => ({
  statuses: [
    UploaderStatus.Added,
    UploaderStatus.Prepared,
    UploaderStatus.Failed,
  ],
  callback: (job: UploaderJob) => {
    if (job.status === UploaderStatus.Added) {
      setPopupChildren({
        size: FullScreenSize.Fullscreen,
        content: <ProgressView />,
      });
    } else if (job.status === UploaderStatus.Prepared) {
      setPopupChildren({
        size: FullScreenSize.Fullscreen,
        content: <DoneView nbFilesToUpload={job.images.length} />,
      });
    } else if (job.status === UploaderStatus.Failed) {
      setPopupChildren({
        size: FullScreenSize.Fullscreen,
        content: <FailedView />,
      });
    }
  },
});

export default togglePreparingLoaderAndDonePopup;
