/* source code origin : https://usehooks-ts.com/react-hook/use-intersection-observer */

import { RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: Args,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([newEntry]: IntersectionObserverEntry[]): void => {
    setEntry(newEntry);
  };

  const hashThreshold = JSON.stringify(threshold);

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    let observer: IntersectionObserver;
    if (hasIOSupport && !frozen && node) {
      const observerParams = { threshold, root, rootMargin };
      observer = new IntersectionObserver(updateEntry, observerParams);

      observer.observe(node);
    }

    return () => {
      observer?.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, hashThreshold, root, rootMargin, frozen]);

  return entry;
}

export default useIntersectionObserver;
