import React from 'react';

import { FullScreenCatalogView } from '@/app/(Shop)/cart/CartFullScreenContainer/FullScreenCatalog/types';

export enum FullScreenSize {
  Fullscreen = 'fullscreen',
  Middle = 'middle',
  Small = 'small',
  Big = 'big',
  Auto = 'auto',
  Fullwidth = 'fullwidth',
}

export enum FullScreenPosition {
  Center = 'center',
  Bottom = 'bottom',
}

export enum FullScreenButtonStyle {
  Secondary = 'secondary',
  White = 'white',
  Light = 'light',
}

export enum FullScreenButtonPosition {
  Inside = 'inside',
  Outside = 'outside',
  Fullscreen = 'fullscreen',
}

export enum PopupTheme {
  Classic = 'classic',
  Discount = 'discount',
}

export interface PopupChildrenProps {
  content: React.ReactNode | undefined;
  theme?: PopupTheme;
  hasClosingButton: boolean;
  closingButtonStyle?: FullScreenButtonStyle;
  closingButtonPosition?: FullScreenButtonPosition;
  closingButtonRounded?: boolean;
  size: FullScreenSize;
  position?: FullScreenPosition;
  closeBackgroundTapped?: boolean;
  onClose?: () => void;
  transparentBackground?: boolean;
  excludedCloseRefs?: React.RefObject<HTMLElement>[];
}

export type SetPopupChildren = (props?: Partial<PopupChildrenProps>) => void;

export interface FullscreenContext {
  popupChildren: PopupChildrenProps;
  fullScreenParams?: FullScreenCartParams;
}

export interface FullscreenContextAction {
  setPopupChildren: (props?: Partial<PopupChildrenProps>) => void;
  setFullScreenParams: React.Dispatch<
    React.SetStateAction<FullScreenCartParams>
  >;
}

export interface FullScreenCartParams {
  cartItemId?: string;
  type?: 'cropper' | 'catalog';
  view?: FullScreenCatalogView;
  insertionIndex?: number;
  callback?: () => void;
  onConfirm?: () => void;
}
