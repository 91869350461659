import { createContext } from 'react';

import {
  FullScreenButtonStyle,
  FullscreenContext as FullscreenContextType,
  FullscreenContextAction as FullscreenContextActionType,
  FullScreenPosition,
  FullScreenSize,
  PopupTheme,
} from './types';

export const defaultProps = {
  content: undefined,
  theme: PopupTheme.Classic,
  hasClosingButton: false,
  closingButtonStyle: FullScreenButtonStyle.Light,
  size: FullScreenSize.Middle,
  position: FullScreenPosition.Center,
  closeBackgroundTapped: true,
  closingButtonRounded: true,
};

export const FullscreenContext = createContext<FullscreenContextType>({
  popupChildren: defaultProps,
  fullScreenParams: undefined,
});

export const FullscreenContextActions =
  createContext<FullscreenContextActionType>({} as FullscreenContextActionType);
