import { createContext } from 'react';

import { ContextUploader, ContextUploaderAction } from './types';

const UploaderContext = createContext<ContextUploader>({
  allImagesCount: 0,
  uploadedImagesCount: 0,
  preparingJobsImagesCount: 0,
  preparingJobsPreparedImagesCount: 0,
});

const UploaderContextAction = createContext<ContextUploaderAction>(
  {} as ContextUploaderAction,
);

export { UploaderContext, UploaderContextAction };
